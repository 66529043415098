import React from "react"
import { Link, graphql } from "gatsby"

import Menu from "../components/menu"

const TastingPage = ({ data, location }) => {
  const content = data.allWpSlider.edges

  const setClassActive = "tasting"

  if (content) {
    return (
      <div className="main_wrapper">
        <Menu content={content} setClassActive={setClassActive}></Menu>
        <div className="tasting_notes">
          <div className="tasting_notes_title">Tasting notes</div>
          <div className="wrapper">
            <div className="row">
              <div className="col col-4">
                <Link
                  to={"/category/gin"}
                  state={{ content }}
                  className={`${
                    setClassActive === "tasting" ? "active" : "inactive"
                  }`}
                >
                  <div className="category gin">
                    <p>Gin</p>
                  </div>
                </Link>
              </div>
              <div className="col col-4">
                <Link
                  to={"/category/rum"}
                  state={{ content }}
                  className={`${
                    setClassActive === "tasting" ? "active" : "inactive"
                  }`}
                >
                  <div className="category rum">
                    <p>Rum</p>
                  </div>
                </Link>
              </div>
              <div className="col col-4">
                <Link
                  to={"/category/vodka"}
                  state={{ content }}
                  className={`${
                    setClassActive === "tasting" ? "active" : "inactive"
                  }`}
                >
                  <div className="category vodka">
                    <p>Vodka</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col col-4">
                <Link
                  to={"/category/sparkling_wine"}
                  state={{ content }}
                  className={`${
                    setClassActive === "tasting" ? "active" : "inactive"
                  }`}
                >
                  <div className="category sparkling_wine">
                    <p>Sparkling Wine</p>
                  </div>
                </Link>
              </div>
              <div className="col col-4">
                <Link
                  to={"/category/wine"}
                  state={{ content }}
                  className={`${
                    setClassActive === "tasting" ? "active" : "inactive"
                  }`}
                >
                  <div className="category wine">
                    <p>Wine</p>
                  </div>
                </Link>
              </div>
              <div className="col col-4"></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return <div></div>
}

export default TastingPage

export const query = graphql`
  query {
    allWpSlider {
      edges {
        node {
          id
          slug
          title
          slider_acf {
            description
            title
            subTitle
            productImage {
              sourceUrl
            }
            category
            boxContent {
              finish
              nose
              palate
            }
          }
        }
      }
    }
  }
`
